(function ( $ ) {
     $.fn.projectMain = function (options) {
        var scrollTop = 0;
        var objBody   = $(this);

        function _init() {
            _initMenu();
            _initWow();
            _initPhotogalery();
        }

        function _initMenu(){
            $("#head .mobileNav").click(function(){
                if($('#head ul').hasClass('active')){
                    $("#head .mobileNav i").removeClass('icon-close').addClass('icon-menu');

                    $('#head ul').removeClass('active');
                    $('body').removeClass('locked');
                }else{
                    $("#head .mobileNav i").addClass('icon-close').removeClass('icon-menu');

                    $('#head ul').addClass('active');
                    $('body').addClass('locked');
                }
            });
        }


        function _initPhotogalery(){
            var owlNews = $('.owl-news');
            owlNews.owlCarousel({
                loop: false,
                margin: 25,
                nav: true,
                dots: false,
                stagePadding:5,
                autoHeight: true,
                navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
                responsive : {
                    0 : {
                        items: 1,
                        slideBy: 1
                    },
                    768 : {
                        items: 2,
                        slideBy: 2
                    },
                    880 : {
                        items: 3,
                        slideBy: 3
                    },
                    1100 : {
                        items: 4,
                        slideBy: 4
                    }
                },
                autoplay:false
            });

            $('.photogalery').magnificPopup({
                type: 'image',
                delegate: 'a',
                gallery: {
                    enabled: true
                }
            });

            $('.post-content,.text-img').magnificPopup({
                type: 'image',
                delegate: 'a.photo',
                gallery: {
                    enabled: true
                }
            });
            $("a.ajaxMorePhotos").click(function(){
                $('.photogalery .photo').removeClass('hidden');
                $(this).addClass('hidden');
            });



        }

        function _initWow(){
            wow = new WOW(
                      {
                      boxClass:     'wow',
                      animateClass: 'animated',
                      offset:       0,
                      mobile:       true,
                      live:         true
                    }
                )
            wow.init();
        }

        _init();
       return {
         reload:_init,
       };
    };
}( jQuery ));

$("body").projectMain();
